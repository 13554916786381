body { margin:0; padding:0; border:0; background:#d5d3d3; }
* { margin:0; padding:0; font-family:Arial, Helvetica, sans-serif; }
.clear-both { clear:both }
a { color:#FFF;}
img { border:none;}

#main { margin:0 auto; width:1050px; }

#blackscreen {  position: absolute; left: 0; top: 0; background-color:#000000; }


#dropshadow { width:1050px; float:left; background:url(../images/dropshadow.png) repeat-y; }
	#wrapper { width:980px; float:left; margin-left:35px; }
	
#header { width:980px; height:150px; float:left; background:#FFF; background:url(../images/header-bg.jpg) no-repeat; }
	#member-info { width:410px; height:90px; float: left; border-radius:10px; background:#FFFFFF; border:1px solid #CCC; margin-left:50px; margin-top:30px;  }
		.header-title { font-weight:bold; color:#666; padding:10px 15px; font-size:14px; }
		.header-info { color:#666; font-size:14px; padding:10px 15px; }
		#logout { float: right; margin-top:50px; margin-right:20px; }
	#branch-info { width:310px; height:90px; float: left; border-radius:10px; background:#FFFFFF; border:1px solid #CCC; margin-left:50px; margin-top:30px; }
	#GMB-logo { width:95px; height:95px; float:right; margin-top:30px; margin-right:40px; }

#title-bar { height:42px; width:980px; float:left; background:url(../images/title-bar-bg.jpg) repeat-x; }
	#title-bar h1 { margin-left:50px; margin-top:5px; float:left; }

#content { padding-left:50px; padding-right:40px; min-height:450px; float:left; background:#FFFFFF}
	#breadcrumbs { color:#666; font-size: 14px; float:left; margin-bottom:30px; width:890px}
	#repquart { color:#666; font-size: 12px; width:300px}
	.select-box { float:left; margin-left:60px; }
	.p-content { width:890px;}
	
#error { border:1px solid #F00; background-color:#ffe5e5; padding:20px; width:800px; margin:20px; -moz-box-shadow: 10px 10px 5px #ccc; -webkit-box-shadow: 10px 10px 5px #ccc; box-shadow: 10px 10px 5px #ccc; }
#error p { font-size:14px; color:#F00 }

#warning { border:1px solid #f5ac39; background-color:#ffdba3; padding:20px; width:800px; margin:20px; -moz-box-shadow: 10px 10px 5px #ccc; -webkit-box-shadow: 10px 10px 5px #ccc; box-shadow: 10px 10px 5px #ccc; }
#warning p { font-size:14px; color:#d48200 }

#success { border:1px solid #060; background-color:#e3fbf4; padding:20px; width:800px; margin:20px; -moz-box-shadow: 10px 10px 5px #ccc; -webkit-box-shadow: 10px 10px 5px #ccc; box-shadow: 10px 10px 5px #ccc; }

#success p { font-size:14px; color:#060 }

/************************************************central page switches***********************************/

/* table hightlight */
.bgrd {background-color:#FFFFFF; cursor:pointer; color:#666;}
.bgrd:hover { background:#CCC;}
.bgrd_selected {background-color:#666;cursor:pointer; color:#FFF;}

/* sliding div */
.slidingDiv { float:right; width:170px; padding:20px; margin-top:10px; border-bottom:5px solid #F00;}
.show_hide { display:none; }

/* print/view page */
	#printpage-layout { list-style:none; float:left; margin-left:100px;}
		.layoutli { margin-bottom:40px; }
		.select-box2 { float:left; margin-top:7px; }
		
/* admin main menu page */
	#admin-left { list-style:none; float:left; margin-bottom:20px; }
	#admin-logo { float:left; margin-left:150px; margin-top:80px; }
	#admin-right { list-style:none; float:left; margin-left:120px; margin-bottom:20px; }
	#center-exit { margin-left:415px; margin-top:-50px; }
	
/* enter/amend branch income/expenditure pages */
	#leftcol, leftcol h2 { float:left; width:120px; }
	#rightcol { float:right;}
		#amendline { width:20px; float:right; border: 1px solid #999; margin-top:-19px; color:#999; padding:3px; font-weight:bold;}
	#amendlinelist { float:left; margin-left:150px; margin-top:-15px; }
		.listamend { padding:5px; color:#999; font-weight:bold; text-align:center; border: 1px solid #999; }
	.dottedline { height:1px; width:100%; border-bottom:1px dotted #999; }
	#incometable-header { height:29px; width:100%; margin-top:20px; background:url(../images/table-header.jpg) repeat-x; text-align:center; }
	#income-table { margin-bottom:20px; }
		#income-table td { padding:5px; border:1px solid #ccc; font-size:14px; font-weight:bold; }
			.red {color:#F00;}
			.red a {color:#F00; text-decoration:none}
			.red a:hover {color:#a00; text-decoration:underline}
			.gray {color:#666;}
			.green {color:#090;}
			
/* Branch Account/balance page */
	#accountlist { margin-left:0;}

/*******************************************************************************************************/

	#buttons { margin-top:20px; width:850px; margin:0 auto; padding-left:40px}
		.button-text { color:#FFF; font-weight:bold;  font-size:12px;}
		
		#corner-left-1 { float:left; background:url(../images/buttons/green-button-left-corner.jpg); height:31px; width:10px; }
		#green-button { float:left; background:url(../images/buttons/green-button-middle.jpg) repeat-x; height:32px; padding:8px 15px 8px 15px; width:130px; text-align:center; }
		#corner-right-1 { float:left; background:url(../images/buttons/green-button-right-corner.jpg); height:31px; width:10px; margin-right:50px; }  
		
		#corner-left-2 { float:left; background:url(../images/buttons/blue-button-left-corner.jpg); height:31px; width:10px; }
		#blue-button { float:left; background:url(../images/buttons/blue-button-middle.jpg) repeat-x; height:32px; padding:8px 15px 8px 15px; width:130px; text-align:center;}
		#corner-right-2 { float:left; background:url(../images/buttons/blue-button-right-corner.jpg); height:31px; width:10px; margin-right:50px; }  
		
		#corner-left-3 { float:left; background:url(../images/buttons/grey-button-left-corner.jpg); height:31px; width:10px; }
		#grey-button { float:left; background:url(../images/buttons/grey-button-middle.jpg) repeat-x; height:32px; padding:8px 15px 8px 15px; width:130px; text-align:center; }
		#corner-right-3 { float:left; background:url(../images/buttons/grey-button-right-corner.jpg); height:31px; width:10px; margin-right:50px; }  
		
		#corner-left-4 { float:left; background:url(../images/buttons/red-button-left-corner.jpg); height:31px; width:10px; }
		#red-button { float:left; background:url(../images/buttons/red-button-middle.jpg) repeat-x; height:32px; padding:8px 15px 8px 15px;  width:130px; text-align:center;}
		#corner-right-4 { float:left; background:url(../images/buttons/red-button-right-corner.jpg); height:31px; width:10px; }  
		
		#footer { min-height:106px; width:980px; background:url(../images/footer-bg.jpg) repeat-x; float:left;}
			#footer-menu { display:inline; float:left; height:10px; margin-left:50px; margin-top:20px; }
			#footer-menu a { text-decoration:none; }
			#footer-menu a:hover { text-decoration: underline; }
				#footer-menu2 { display:inline; float:right; height:10px; margin-right:40px; margin-top:20px;}
				#footer-menu2 a { text-decoration:none; }
			#footer-menu2 a:hover { text-decoration: underline; }
					#footer-menu li, #footer-menu2 li { color:#FFF; float:left; list-style:none; border-right:solid #FFF 1px; font-size:12px; padding:0 4px 2px 4px; height:10px;}
			#footer-strap { color:#FFF; font-size:12px; margin-top:40px; margin-left:50px;}
			
		.fade {
   opacity: 1;
   transition: opacity .25s ease-in-out;
   -moz-transition: opacity .25s ease-in-out;
   -webkit-transition: opacity .25s ease-in-out;
   }

   .fade:hover {
      opacity: 0.5;
      }
		
/* Globals */
h1 { color:#FFFFFF; font-size:18px; }
h2 { color:#ef464b; font-size:12px; }
p  { color:#666; font-size:12px; font-weight:bold; }

.layoutli a { color:#ef464b; }

#breadcrumbs a { color:#666; font-size:14px; font-weight:bold; text-decoration:none; border-bottom: solid 1px; }
#breadcrumbs a:hover { text-decoration:none; } 


/* BUTTONS */
.deletebutton { float:left; margin-right:30px; background:url(../images/bgrd-button-delete.gif) top left no-repeat; width:180px; height:31px; border:0; color:#FFF; font-weight:bold; font-family:Arial, Helvetica, sans-serif; font-size:11px; cursor:pointer; padding-bottom:3px; }

.cancelbutton { float:left; margin-right:30px; background:url(../images/bgrd-button-cancel.gif) top left no-repeat; width:180px; height:31px; border:0; color:#FFF; font-weight:bold; font-family:Arial, Helvetica, sans-serif; font-size:11px; cursor:pointer; padding-bottom:3px;  }

.savebutton { float:left; margin-right:30px; background:url(../images/bgrd-button-save.gif) top left no-repeat; width:180px; height:31px; border:0; color:#FFF; font-weight:bold; font-family:Arial, Helvetica, sans-serif; font-size:11px; cursor:pointer; padding-bottom:3px;  }


.greenmodern {
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font-size: 12px;
	padding: .4em 1.5em .42em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);

	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	box-shadow: 0 1px 2px rgba(0,0,0,.2);
	color: #e8f0de;
	border: solid 1px #538312;
	background: #64991e;
	background: -webkit-gradient(linear, left top, left bottom, from(#7db72f), to(#4e7d0e));
	background: -moz-linear-gradient(top,  #7db72f,  #4e7d0e);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#7db72f', endColorstr='#4e7d0e');
}
.greenmodern:hover {
	text-decoration: none;
	background: #538018;
	background: -webkit-gradient(linear, left top, left bottom, from(#6b9d28), to(#436b0c));
	background: -moz-linear-gradient(top,  #6b9d28,  #436b0c);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#6b9d28', endColorstr='#436b0c');
}
.greenmodern:active {
	position: relative;
	top: 1px;
	color: #a9c08c;
	background: -webkit-gradient(linear, left top, left bottom, from(#4e7d0e), to(#7db72f));
	background: -moz-linear-gradient(top,  #4e7d0e,  #7db72f);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#4e7d0e', endColorstr='#7db72f');
}

.redmodern {
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font-size: 12px;
	padding: .4em 1.5em .42em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	box-shadow: 0 1px 2px rgba(0,0,0,.2);
	color: #faddde;
	border: solid 1px #980c10;
	background: #d81b21;
	background: -webkit-gradient(linear, left top, left bottom, from(#ed1c24), to(#aa1317));
	background: -moz-linear-gradient(top,  #ed1c24,  #aa1317);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#ed1c24', endColorstr='#aa1317');
}
.redmodern:hover {
	text-decoration: none;
	background: #b61318;
	background: -webkit-gradient(linear, left top, left bottom, from(#c9151b), to(#a11115));
	background: -moz-linear-gradient(top,  #c9151b,  #a11115);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#c9151b', endColorstr='#a11115');
}
.redmodern:active {
	position: relative;
	top: 1px;
	color: #de898c;
	background: -webkit-gradient(linear, left top, left bottom, from(#aa1317), to(#ed1c24));
	background: -moz-linear-gradient(top,  #aa1317,  #ed1c24);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#aa1317', endColorstr='#ed1c24');
}


.bluemodern {
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font-size: 12px;
	padding: .4em 1.5em .42em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
	box-shadow: 0 1px 2px rgba(0,0,0,.2);
	color: #d9eef7;
	border: solid 1px #0076a3;
	background: #0095cd;
	background: -webkit-gradient(linear, left top, left bottom, from(#00adee), to(#0078a5));
	background: -moz-linear-gradient(top,  #00adee,  #0078a5);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
}
.bluemodern:hover {
	text-decoration: none;
	background: #007ead;
	background: -webkit-gradient(linear, left top, left bottom, from(#0095cc), to(#00678e));
	background: -moz-linear-gradient(top,  #0095cc,  #00678e);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#0095cc', endColorstr='#00678e');
}
.bluemodern:active {
	position: relative;
	top: 1px;
	color: #80bed6;
	background: -webkit-gradient(linear, left top, left bottom, from(#0078a5), to(#00adee));
	background: -moz-linear-gradient(top,  #0078a5,  #00adee);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#0078a5', endColorstr='#00adee');
}

/* Container */

body {
    margin: 0;
    padding: 0;
    border: 0;
    background: #f6f6f6;
}

#wrapper {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media (min-width: 992px) {
    #wrapper {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    #wrapper {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    #wrapper {
        max-width: 1320px;
    }
}

#main {
    width: 100%;
    margin: auto;
    display: flex;
}

#content {
    padding-top: 10px;
    padding-bottom: 10px;
}

#dropshadow {
    width: 100%;
    float: none;
    display: flex;
    background: none;
    margin: auto;
}

#header,
#title-bar,
#content,
#center-exit,
#footer {
    width: calc(100% - 100px);
    padding: 0 50px;
}

#buttons,
#breadcrumbs {
    width: calc(100% - 100px);
}

#breadcrumbs {
    margin-top: 10px;
}

#footer {
    background: #333;
    margin-top: auto;
}

#header {
    height: auto;
    background: #fff;
}

#header,
#center-exit {
    background-size: cover;
    display: flex;
}

#member-info,
#branch-info,
#GMB-logo {
    margin: 15px auto;
    border-radius: 0;
    border: none;
    height: unset;
    width: unset;
	display: flex;
}

#member-info table,
#branch-info table {
    height: 100%;
    width: auto;
}

.header-info, .header-title {
	padding: 0;
	font-size: 14px;
}

#logout {
    margin: 0;
}

#center-exit {
    margin-left: unset;
    margin-top: unset;
    margin: auto;
}

#member-info,
#footer-menu,
#footer-strap {
    margin-left: 0;
}

#GMB-logo,
#footer-menu2 {
    margin-right: 0;
}

#title-bar {
    padding-top: 10px;
    padding-bottom: 10px;
    height: unset;
    line-height: unset;
    background: #FF6A13;
}

#title-bar h1 {
    margin: 0;
}


/* Tables */

table {
    max-width: 100%;
}

#incometable-header {
    padding-top: 10px;
    padding-bottom: 10px;
    height: unset;
    line-height: unset;
    background: #FF6A13;
}

#incometable-header h1 {
    margin: 0;
}

#incometable-header {
    background-color: #999;
}

#income-table {
    border-collapse: collapse;
    width: 100%;
}

.bgrd:hover {
    background: #ededed;
}

.bgrd_selected {
    background: #666;
}

.bgrd_selected p.td {
    color: #fff
}

#center-exit input {
    margin: auto;
}


/* Admin */

#admin-left {
    float: left;
    list-style: none;
    margin-left: 0;
}

#admin-logo {
    width: 300px;
    height: 0;
    margin: auto;
    float: none;
}

#admin-right {
    float: right;
    list-style: none;
    margin-left: 0;
}


/* Buttons */

.logout {
    display: inline-block;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    padding: .4em 1.5em .42em;
    color: #fff;
    border: solid 1px #FF6A13;
    background: #FF6A13;
}

.greenmodern,
.redmodern,
.bluemodern,
.logout {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    text-shadow: none;
    box-shadow: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.greenmodern,
.greenmodern:active,
.greenmodern:hover {
    background: #00a85a;
    border-color: #00a85a;
    color: #fff;
}

.redmodern,
.redmodern:active,
.redmodern:hover {
    background: #F44336;
    border-color: #F44336;
    color: #fff;
}

.bluemodern,
.bluemodern:active,
.bluemodern:hover {
    background: #00a79d;
    border-color: #00a79d;
    color: #fff;
}

.logout {
    background: #FF6A13;
    border-color: #FF6A13;
    color: #fff;
}

.logout:hover {
    text-decoration: none;
    background: #FF6A13;
}

.logout:active {
    position: relative;
    top: 1px;
    color: #fff;
    background: #FF6A13;
}